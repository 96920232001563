import './style.css';
import {Feature, Map, View} from 'ol';
import {transform} from 'ol/proj';
import {Point} from 'ol/geom';
import {Icon, Style} from 'ol/style';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';

import Draw from 'ol/interaction/Draw';
import {inject} from '@vercel/analytics';

inject();

const API_ENDPOINT = import.meta.env.VITE_API_URL;
const raster = new TileLayer({
    source: new OSM(),
});


const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

var mapSource = new VectorSource({wrapX: false});

const vector = new VectorLayer({
    source: mapSource,
});

const defaultLayers = [raster, vector];

const map = new Map({
    layers: defaultLayers,
    target: 'map',
    view: new View({
        center: transform([-46.6396, -23.5558], 'EPSG:4326', 'EPSG:3857'),
        zoom: 13
    }),
});

map.once('postrender', async (event) => {
    if (window.location.pathname.startsWith("/s/")) {
        let shareId = window.location.pathname.substring(3);
        try {
            let response = await fetch(`${API_ENDPOINT}/results/${shareId}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((rawResponse) => rawResponse.json());
            let transformedLatLongs = response.polygonQueryRequest.polygonCoordinates.map(latLong =>
                transform([latLong.longitude, latLong.latitude], 'EPSG:4326', 'EPSG:3857')
            );
            const geojsonObject = {
                'type': 'FeatureCollection',
                'crs': {
                    'type': 'name',
                    'properties': {
                        'name': 'EPSG:3857',
                    },
                },
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [
                                transformedLatLongs,
                            ],
                        },
                    },
                ],
            };
            var zoom = 11;
            let area = response.detailedResultsResponse.area;
            if (area > 1 && area < 5) {
                zoom = 12;
            } else if (area > 5 && area < 20) {
                zoom = 7;
            } else if (area > 20) {
                zoom = 3;
            }
            map.setView(new View({
                center: transform([
                        response.detailedResultsResponse.polygonCenter.longitude,
                        response.detailedResultsResponse.polygonCenter.latitude
                    ],
                    'EPSG:4326',
                    'EPSG:3857'
                ),
                zoom: zoom
            }));
            map.addLayer(new VectorLayer({
                source: new VectorSource({
                    features: new GeoJSON().readFeatures(geojsonObject),
                })
            }))
            handleResponse(response.detailedResultsResponse, response.electionResults);
        } catch (e) {
            console.error(e);
        }
    }
});


const drawButton = document.getElementById('draw-button');

function appendCell(content, header, table, elem = 'th') {
    var cell = document.createElement(elem);
    cell.textContent = content;
    cell.setAttribute('scope', 'col');
    header.appendChild(cell);
    table.appendChild(header);
}

var currentlyDrawing = false;
var markersSource = null;

let shareGridId = '#share-grid';
let drawnInstructionsElement = document.getElementById('draw-instructions');
let resultsTable = document.querySelector('#results-container table');

function hide(selector) {
    document.querySelector(selector).setAttribute('class', 'hidden');
}

function showElement(element) {
    element.setAttribute('class', element.getAttribute('class').replaceAll('hidden', ''));
}

function hideElement(element) {
    element.setAttribute('class', element.getAttribute('class') + ' hidden');
}

drawButton.addEventListener('click', () => {
    if (currentlyDrawing) {
        return;
    }
    currentlyDrawing = true;
    hideElement(resultsTable);
    resultsTable.innerHTML = "";
    hide(shareGridId);
    showElement(drawnInstructionsElement);
    map.getLayers().getArray()[1].getSource().clear();
    if (map.getLayers().getArray()[2]) {
        map.getLayers().getArray()[2].getSource().clear();
    }
    if (markersSource) {
        markersSource.clear();
    }
    const draw = new Draw({
        source: mapSource,
        type: 'Polygon'
    });
    map.addInteraction(draw);
    draw.on('drawend', async function (end) {
        currentlyDrawing = false;
        var coordinatesPayload = end.feature.getGeometry().getCoordinates()[0].map(lonLat => {
            let transformed = transform(lonLat, 'EPSG:3857', 'EPSG:4326');
            return {
                latitude: transformed[1],
                longitude: transformed[0]
            };
        });

        await handleCoordinates(coordinatesPayload);
        map.removeInteraction(draw);
    });


    document.getElementById('close-modal').addEventListener('click', () => {
        document.getElementById('polling-station')
            .setAttribute("class", 'hidden')
    });
});

function handleResponse(detailsResponse, response) {
    var features = [];
    detailsResponse.pollingStationResults.forEach(pollingStationResult => {
        let transformed = transform([
                pollingStationResult.pollingStation.coordinate.longitude,
                pollingStationResult.pollingStation.coordinate.latitude
            ],
            'EPSG:4326',
            'EPSG:3857'
        );
        var iconFeature = new Feature({
            geometry: new Point(transformed),
            pollingStation: pollingStationResult
        });

        iconFeature.setStyle(new Style({
            image: new Icon(({
                anchor: [0.5, 1],
                src: "https://cdn.mapmarker.io/api/v1/font-awesome/v5/pin?size=40&background=" + pollingStationResult.markerColor + "&color=FFF&hoffset=-1"
            }))
        }));
        features.push(iconFeature);

        map.on('click', function (event) {
            map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                if (feature.get("pollingStation")) {
                    const modal = document.getElementById('polling-station');
                    modal.setAttribute("class", '')
                    let result = feature.get("pollingStation");
                    document.querySelector('#polling-station b').textContent = result.pollingStation.name;
                    // document.querySelector('#polling-station p').textContent = result.pollingStation.address;

                    let table = document.querySelector('#polling-station-results-table');
                    table.innerHTML = '';
                    let header = document.createElement('tr');

                    appendCell('Candidato', header, table);
                    appendCell('Votos', header, table);
                    appendCell('', header, table);

                    result.votesByCandidate.forEach(votes => {
                        let row = document.createElement('tr');

                        appendCell(votes.name, row, table, 'td');
                        appendCell(votes.votes.toLocaleString(), row, table, 'td');

                        table.appendChild(row);
                    });

                }
            });
            document.getElementById('close-modal').addEventListener('click', () => {
                document.getElementById('polling-station')
                    .setAttribute("class", 'hidden')
            });
        });

    });

    var vectorSource = new VectorSource({
        features: features
    });
    markersSource = vectorSource;

    map.addLayer(new VectorLayer({
        source: vectorSource
    }));

    let desc = document.createElement('b');

    document.getElementById('loading').setAttribute('class', 'hidden');
    showElement(resultsTable);
    showElement(document.querySelector(shareGridId));

    desc.textContent = 'Resultados na área selecionada';
    let header = document.createElement('tr');

    appendCell('Candidato', header, resultsTable);
    appendCell('Votos', header, resultsTable);
    appendCell('', header, resultsTable);

    response.votesByCandidate.forEach(votes => {
        let row = document.createElement('tr');

        appendCell(votes.name, row, resultsTable, 'td');
        appendCell(votes.votes.toLocaleString(), row, resultsTable, 'td');
        appendCell(votes.percentage + "%", row, resultsTable, 'td');

        resultsTable.appendChild(row);
    });

    let shareUrl = 'https://www.urnamaps.com/s/' + response.shareId;
    let shareLink = document.querySelector('#share-grid .shareable-link');
    shareLink.innerHTML = shareUrl;
    shareLink.setAttribute('href', shareUrl);
}

async function handleCoordinates(coordinatesPayload) {
    hideElement(drawnInstructionsElement);
    showElement(document.getElementById('loading'));

    let details = fetch(API_ENDPOINT + '/results/detailed', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            polygonCoordinates: coordinatesPayload
        })
    }).then((rawResponse) => rawResponse.json());

    let response = await fetch(API_ENDPOINT + '/results', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            polygonCoordinates: coordinatesPayload
        })
    }).then((rawResponse) => rawResponse.json());

    handleResponse(await details, response);
}


let copyShareableLinkButton = document.querySelector('#share-grid button');
copyShareableLinkButton.addEventListener('click', () => {
    let link = document.querySelector('#share-grid a').getAttribute('href');
    navigator.share({
        title: 'urnamaps',
        text: 'Confira os resultados do segundo turno de 2022 nessa área demarcada no mapa',
        url: link
    });
});
